import React from 'react';
import './App.css';

function App() {
  return (
    <div className="app">
      <header className="header">
        <div className="dev-banner">🚧 Under Development</div>
        <h1>Welcome to Elancore</h1>
        <p>
          Where Full Stack Web Development meets ML/AI integration and custom LLM training.
        </p>
      </header>
      <main className="container">
        <section className="services">
          {/* Full Stack Web Development */}
          <div className="service-card">
            <div className="icon" role="img" aria-label="Full Stack Web Development">💻</div>
            <h3>Full Stack Web Development</h3>
            <p>
              Crafting modern, responsive websites that scale with your business needs.
            </p>
          </div>
          {/* ML/AI Development & Integration */}
          <div className="service-card">
            <div className="icon" role="img" aria-label="ML/AI Development & Integration">🤖</div>
            <h3>ML/AI Development & Integration</h3>
            <p>
              Infusing intelligent algorithms into your systems for smarter solutions.
            </p>
          </div>
          {/* Custom LLM Training */}
          <div className="service-card">
            <div className="icon" role="img" aria-label="Custom LLM Training">🧠</div>
            <h3>Custom LLM Training</h3>
            <p>
              Tailoring language models to empower your business with cutting-edge AI.
            </p>
          </div>
          {/* Web & Mobile Design */}
          <div className="service-card">
            <div className="icon" role="img" aria-label="Web & Mobile Design">📱</div>
            <h3>Web &amp; Mobile Design</h3>
            <p>
              Creating intuitive user experiences that captivate your audience.
            </p>
          </div>
          {/* Digital Marketing */}
          <div className="service-card">
            <div className="icon" role="img" aria-label="Digital Marketing">📈</div>
            <h3>Digital Marketing</h3>
            <p>
              Elevating your brand with strategic marketing campaigns that deliver results.
            </p>
          </div>
          {/* Branding & Logo Design */}
          <div className="service-card">
            <div className="icon" role="img" aria-label="Branding & Logo Design">🎨</div>
            <h3>Branding &amp; Logo Design</h3>
            <p>
              Establishing a unique identity that sets you apart from the competition.
            </p>
          </div>
        </section>
      </main>
      <footer>
        <p>&copy; {new Date().getFullYear()} Elancore Agency. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
